import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Layout from '../components/layout'
import Seo from '../components/seo'
import Reviews from "./partials/reviews";
import Socials from "./partials/socials";

const IndexPage = () => {
  const { site, imgElearning, imgChat, imgForum, imgWebinar } = useStaticQuery(
    graphql`{
  site {
    siteMetadata {
      title
      joinUrl
      sharedScreenProductUrl
    }
  }
  imgElearning: file(
    sourceInstanceName: {eq: "images"}
    relativePath: {eq: "img-elearning.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 137, quality: 100, layout: FIXED)
    }
  }
  imgChat: file(
    sourceInstanceName: {eq: "images"}
    relativePath: {eq: "img-chat.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 142, quality: 100, layout: FIXED)
    }
  }
  imgForum: file(
    sourceInstanceName: {eq: "images"}
    relativePath: {eq: "img-forum.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 145, quality: 100, layout: FIXED)
    }
  }
  imgWebinar: file(
    sourceInstanceName: {eq: "images"}
    relativePath: {eq: "img-webinar.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 137, quality: 100, layout: FIXED)
    }
  }
}
`
  )

  return (
    <Layout>
      <Seo title="Home" />
      <div className="border-b border-gray-100">
        <div className="main-container">
          <div className="welcome text-center mt-10 sm:mt-24 md:mt-32 sm:mx-10 md:mx-20 mb-20">
            <h1 className="text-4xl sm:text-6xl font-bold font-display uppercase">Get Started with {site.siteMetadata.title} Today!</h1>
            <p className="mt-5 sm:mt-10 text-lg uppercase">{site.siteMetadata.title} is the educational community that helps you go from a beginner to a self sufficient trader. Join now to get started.</p>
            <p className="mt-5 sm:mt-10 text-lg">Unlock Your Trading Potential: Subscribe now for exclusive live trading sessions with screen sharing and learn the art of growing your trading account with expert guidance!</p>
            <a href={site.siteMetadata.sharedScreenProductUrl} target="_blank" rel="noreferrer" className="inline-block btn btn-primary btn-lg mt-10 px-32">Subscribe to live trading sessions!</a>
            <div className="mt-10 border-b border-gray-100" />
            <p className="mt-5 sm:mt-10 text-lg">Level up your trading: Join our Discord server for real-time stock trading signals and elevate your investment game!</p>
            <a href={site.siteMetadata.joinUrl} target="_blank" rel="noreferrer" className="inline-block btn btn-primary btn-lg mt-10 px-32">Join Now!</a>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-100">
        <div className="main-container">
          <div className="text-center mt-10 sm:mt-24 md:mt-32 sm:mx-10 md:mx-20 mb-20">
            <h2 className="text-3xl sm:text-5xl font-display font-bold">No matter your experience with trading, we've got your back.</h2>
            <p className="mt-5 sm:mt-10 text-lg">Our training is designed for all leveled traders, whether you’re a complete newbie or someone who’s been trading for months. We’ve got you covered.</p>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-100">
        <div className="main-container my-20">
          <h2 className="text-center text-3xl sm:text-4xl font-bold font-display">Complete outline of what you’re signing up for</h2>

          {/* Elearning */}
          <div className="sm:mx-20 md:mx-28 mt-20 flex flex-col md:flex-row-reverse">
            <div className="md:w-4/12 sm:text-center">
              <GatsbyImage alt="" className="inline-block" image={imgElearning.childImageSharp.gatsbyImageData} />
            </div>
            <div className="mt-6 md:mt-0 md:w-8/12">
              <h3 className="text-3xl font-display font-bold">Online E-Learning Platform</h3>
              <p className="mt-4">Watch training videos, listen to recordings or read the textbook. Access the training online from any desktop or mobile device, anywhere in the world!</p>
            </div>
          </div>

          {/* Chat */}
          <div className="sm:mx-20 md:mx-28 mt-20 flex flex-col md:flex-row">
            <div className="md:w-4/12 sm:text-center">
              <GatsbyImage alt="" className="inline-block" image={imgChat.childImageSharp.gatsbyImageData} />
            </div>
            <div className="mt-6 md:mt-0 md:w-8/12">
              <h3 className="text-3xl font-display font-bold">Traders Chat</h3>
              <p className="mt-4">Join an energetic community of 1,000+ traders on the same wavelength as you. Trading is lonely when you’re surrounded by “normal people” who just don’t get it. Get inspired, ask questions, generate ideas, make friends and have fun!</p>
            </div>
          </div>

          {/* Forum */}
          <div className="sm:mx-20 md:mx-28 mt-20 flex flex-col md:flex-row-reverse">
            <div className="md:w-4/12 sm:text-center">
              <GatsbyImage alt="" className="inline-block" image={imgForum.childImageSharp.gatsbyImageData} />
            </div>
            <div className="mt-6 md:mt-0 md:w-8/12">
              <h3 className="text-3xl font-display font-bold">Traders Forum</h3>
              <p className="mt-4">Get personalized mentorship from experts who can troubleshoot your problems and provide direction. All courses include weekly Q&A calls, recordings, student interviews, live stream sessions, and 24/7/365 support.</p>
            </div>
          </div>

          {/* Webinars */}
          <div className="sm:mx-20 md:mx-28 mt-20 flex flex-col md:flex-row">
            <div className="md:w-4/12 sm:text-center">
              <GatsbyImage alt="" className="inline-block" image={imgWebinar.childImageSharp.gatsbyImageData} />
            </div>
            <div className="mt-6 md:mt-0 md:w-8/12">
              <h3 className="text-3xl font-display font-bold">Weekly Webinars</h3>
              <p className="mt-4">We hold several webinars on a regular basis for our traders to make sure they are all on the same path and understand the concepts properly.</p>
            </div>
          </div>
        </div>
      </div>
      <Reviews joinUrl={site.siteMetadata.joinUrl} />
      <Socials />
    </Layout>
  );
}

export default IndexPage
