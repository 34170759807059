import React from "react";

const Reviews = (props) => {
  const { joinUrl } = props
  
  return (
    <div id="reviews" className="border-b border-gray-100">
      <div className="main-container">
        <div className="text-center mt-6 sm:mt-16 md:mt-24 sm:mx-10 md:mx-20 mb-20">
          <h1 className="text-3xl sm:text-5xl font-bold font-display uppercase">
            Reviews
          </h1>
          <p className="mt-5 sm:mt-10 text-lg">
            Here are some of the reviews from our members
          </p>
          <div className="review-item my-8">
            <p className="review italic">
              I'm walking away cause I'm getting greedy. Gonna leave green.
              Thanks Ari - this should serve as a true testimonial to the amount
              of knowledge and help that you have provided me with. I was down
              bad today only because of an accident really, but followed all the
              rules and methods that you've taught me and coupling that with
              some patience and am now closing green. I gain more and more
              confidence every day with each trade I take. I'm certainly not
              ripping 40k gainers yet, but I don't ever plan to stop and
              continue to get better and better at this until I'm as successful
              as you are. You've clearly given me a huge edge in this game since
              you started teaching me about a month ago and I appreciate it.
            </p>
            <p className="author font-bold">- Corey</p>
          </div>
          <div className="review-item my-8">
            <p className="review italic">
              No words for how thankful I am with Arvind.. still remember first
              day I was introduced to stock market I tried learning on my own
              but was really hard until got in touch with you. Very thankful to
              you, making all this hard stuff easy to understand, I was scare to
              take a trade before and must of the time when I did was basically
              a bet and ended up losing money or returning whatever I made
              before, now I feel more confident when taking a trade. I don't
              trade every day do to work but when I do definitely making
              profitable trades. All the rules, methods and strategies you have
              taught me are definitely making me very successful in this game..
              5 stars brother.
            </p>
            <p className="author font-bold">- Tordekfs</p>
          </div>
          <div className="review-item my-8">
            <p className="review italic">
              Ari has not only changed how I trade but has changed my life. I
              went from being very inconsistent with small wins and big loses to
              now having the confidence and knowledge have consistent green
              days. I'm still learning but this has literally changed how I'll
              live moving forward. From the discord to the strategies and 1 on 1
              time he gives you the only way to fail is if you do not listen.
              I've already recommended it to friends and family and I recommend
              it to anyone who wants to up their trading game.
            </p>
            <p className="author font-bold">- Rowe</p>
          </div>
          <div className="review-item my-8">
            <p className="review italic">
              Ari, want to say thank you for teaching me the fundamentals of
              stock market and how to analyze and execute trades. Prior to
              taking your mentorship course, I was struggling to take trades,
              risk management and position sizing. I was loosing more than I was
              winning. But with your teaching I can say I have been making small
              gains because I get out of the contracts to soon (something I need
              to work on) but I have been making consistent profitable trades.
              Thank you for everything and keep killing it. Your attitude of
              everything has enough to eat on the market is what impressed me
              the most.
            </p>
            <p className="author font-bold">- Simba</p>
          </div>
          <a
            href={joinUrl}
            className="inline-block btn btn-primary btn-lg mt-10 px-32"
          >
            Join Now!
          </a>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
