import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const Socials = () => {
  const data = useStaticQuery(graphql`{
    site {
      siteMetadata {
        socialLinks {
          type
          url
        }
      }
    }
    instagramIcon: file(
      sourceInstanceName: { eq: "images" },
      relativePath: {eq: "Instagram_Glyph_Gradient.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 80, 
          quality: 100, 
          layout: FIXED,
          placeholder: NONE
        )
      }
    }
  }`)

  const socialLinks = data.site.siteMetadata.socialLinks

  if (!socialLinks) {
    return null
  }
  
  return (
    <div id="social" className="border-b border-gray-100">
      <div className="main-container">
        <div className="text-center mt-6 sm:mt-16 md:mt-24 sm:mx-10 md:mx-20 mb-20">
          <h1 className="text-3xl sm:text-5xl font-bold font-display uppercase">
            Connect with us
          </h1>
          <div className="text-center my-8">
            {
              socialLinks.map(link => {
                const image = getImage(data[`${link.type}Icon`])
                return <a className="inline-block" href={link.url} key={link.type} target="_blank" rel="noreferrer">
                  <GatsbyImage
                    alt={link.type}
                    image={image}
                  />
                </a>
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socials;
